@font-face {
  font-family: din-bold;
  src: url('./assets/fonts/din-bold_.otf'); /* IE9 */
}

body {
  margin: 0 auto;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  max-width: 750px;
}

* {
  box-sizing: border-box;
}

button {
  background-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (min-width: 750px) {
  html {
    font-size: 75px !important;
  }
}
